<template>
  <div class="detail padding20">
    <!-- <el-row class="breadcrumb" align="middle" type="flex">
    </el-row> -->
    <div class="detail-title">
      <div class="left-box">
        <h3 class="title-header">
        {{shareInfo.name}}
        </h3>
        <div class="datail-bottom">
            <div class="title-date">
              <span style="color:#afafaf">共享时间：</span>
              <span>{{shareInfo.createDate|date}}</span>
            </div>
            <div class="title-man">
              <span style="color:#afafaf">共享人：</span>
              <span>{{shareInfo.ownerName}}</span>
            </div>
        </div>
      </div>
       <div class="right-btns">
          <!-- <el-button type='primary' v-if='shareInfo.canShare' @click="shareAgain">再次共享</el-button> -->
          <el-button type='primary' plain v-if='shareInfo.canShare' @click="shareAgain">再次共享</el-button>
          <el-button type='primary' plain @click="back">返回上级</el-button>
        </div>
    </div>
    <!-- 文件资源 -->
    <ShowFileList :shareInfo="shareInfo" :permissions="permissions"/>
    <!-- 共享目标 -->
    <AddTarget :shareInfo="shareInfo" :permissions="permissions"  :show="false" />
    <!-- 授权操作 -->
    <ShowConferState :shareInfo="shareInfo" :permissions="permissions" />
 
    <!-- 再次共享添加目标 -->
    <!-- <AddSharePeople ref="addSharePeople"  @selected="onTargetAdd" @removed="onTargetRemoved" @submit="onSubmit" /> -->
    <AddShareAgain  :shareInfo="shareInfo"  ref='addShareAgain'></AddShareAgain>

  </div>
</template>
<script>
import { shareAPI } from 'liankong-ui-api' 
import ShowConferState from '@/components/ShowConferState.vue'
import AddTarget from '@/components/AddTarget.vue'
import ShowFileList from '@/components/ShowFileList.vue'
import User from "./UserCompontent";
import AddSharePeople from '@/views/share/AddSharePeople.vue'
import AddShareAgain from '@/packages/FileCart/src/addShareAgain.vue'

export default {
  data() {
    return {
      dreadcrumb: [],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      verker:'shareDocList',
      shareAgainObj:{},//再次共享传参
      shareAgainTarget:[]
    };
  },
  components: {
    User,
    ShowConferState,
    AddTarget,
    ShowFileList,
    AddSharePeople,
    AddShareAgain,
  },
  computed: {
    //共享信息
    shareInfo(){
      return this.$store.state.auth.editShare
    },
    viewer() {
      return this.$store.state.doc.viewer[this.verker] || {}
    },
    //权限信息
    permissions(){
      return this.shareInfo.permissions ? this.shareInfo.permissions.reduce((map, p) => {
        map[p.name] = p.value
        return map
      }, {}) : null
    },
  },
  methods: {
    // 再次共享
    shareAgain(){
   
      this.$refs['addShareAgain'].dialogVisible = true;
      
      
    },
    backFile(){
      this.$store.dispatch('doc/backFolder', {
        vkey: this.verker
      })
    },
    back() {
      // this.$router.replace({path:'/shares/shares'})
      // this.$router.replace({path: '/file/shares'})
      this.$router.go(-1);
    },
    onload(){
      let {id} = this.$route.query
      this.$store.dispatch("auth/loadShare",id).then(data => {
        // this.shareDetail = data.data
        let {resources} = this.$store.state.auth.editShare
        this.$store.commit('doc/createViewer', {
          viewerKey: this.verker,
          defaultFiles: resources
        })
      })
    },
    render(docInfo){
      if(docInfo.type==0){
        this.$store.dispatch("doc/loadChildrenByShare", {
          vkey: this.verker,
          folder: docInfo
        })
        return
      }
      this.$ebus.$emit('publicReader',{
          shareId:this.shareInfo.id, 
          id:docInfo.documentId || docInfo.id,
          documentId: docInfo.documentId
      })
     
    }
  },
  created() {
      
  },
  mounted(){
    this.onload()
  }
};
</script>

<style lang="less">
@import "../../styles/index";
.detail {
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  .detail-title {
    width: 100%;
    min-height: 130px;
    background: #f9fcff;
    border: 1px solid #e7eef4;
    // justify-content: space-around;
    padding: 20px;
    display: flex;
    // flex-flow: column;
    box-sizing: border-box;
    margin-top: 20px;
    .left-box {
      flex: 1;
        .title-header {
          font-size: 29px;
          margin: 0;
          color: #000;
        }
      .datail-bottom {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin-top: 30px;
        font-size: 14px;
        .title-date {
          margin-right: 50px;
          & > span:nth-child(2) {
            color: #000;
          }
        }
        .title-man {
          & > span:nth-child(2) {
            color: #000;
          }
        }
      }
    }
    .right-btns {
      width: 210px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      .el-button.el-button--primary.is-plain {
          background: #fff;
      }
    }
  }
  .detail-title:hover {
    box-shadow: 0px 2px 16px 0px rgba(18, 55, 100, 0.2);
    .right-btns {
      .el-button.el-button--primary.is-plain {
            background: #50a2ef;
            color: #fff;
      }
    }
  }
  .detail-read {
    width: 100%;
    margin-top: 32px;
    .title-box {
      border-bottom: 1px dashed #e7eef4;
      padding-bottom: 10px;
    }
    .read-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        display: inline-block;
        height: 20px;
        width: 5px;
        background: @Brand;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .add {
      font-size: @h3;
      span {
        border-radius: 50px;
        background: #59b880;
        padding: 2px;
        i {
          color: #fff;
        }
      }
    }
    .read-bottom-box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .read-context {
      width: 100%;
      table {
        padding-bottom: 10px;
      }
      .el-table__body {
        tbody {
          display: flex;
          flex-wrap:wrap;
          .el-table__row {
            width:50%;
            td {
              border:0;
            }
          }
        }
      }
      .title-link {
        white-space: nowrap;
        position: relative;
        cursor: pointer;
        font-size: @h3;
      }
    }
    .el-form-item {
      margin-bottom: 10px;
       /deep/ .el-form-item__content {
        margin-left: 90px !important;
      }
    }
  }
  .detail-back {
    width: 100%;
    display:flex;
    justify-content: center;
  }
  .target-context {
    .target-item {
      margin-top: 30px;
      .center {
        margin-right: 50px;
      }
    }
  }
}
.breadcrumb {
  padding: 0 0 15px 0px;
  display: flex;
  justify-content: flex-end;
}
</style>