<template>
  <div class="left" :style="{ backgroundColor: background }">张</div>
</template>
<script>

export default {
  data: function () {
    return {
      color: ["#0084ff", "#67C23A", "#E6A23C", "#F56C6C", "#909399"],
      background: "#0084ff",
    };
  },
  created() {
    this.background = this.color[Math.floor(Math.random() * 5)];
  },
};
</script>