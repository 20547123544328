<template>
    <div class="detail-power detail-read">
      <el-row type="flex" justify="space-between" class="title-box">
        <el-col :span="3"
          ><div class="read-title"><span></span>操作授权</div></el-col
        >
      </el-row>
      <el-form ref="form" label-width="80px">
        <el-form-item label="生效时间: ">
          {{shareInfo.expireStartTime&&shareInfo.expireEndTime
          ? shareInfo.expireStartTime+'-'+shareInfo.expireEndTime : 
          shareInfo.expireEndTime
          ?'结束时间'+ shareInfo.expireEndTime:'永久' }}
        </el-form-item>
        <el-form-item label="可共享: ">
          <el-switch  v-model="permissions.share" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
        </el-form-item>
        <el-form-item label="不可复制: ">
          <el-switch  v-model="permissions.copy" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
        </el-form-item>
        <el-form-item label="可打印: ">
          <el-switch v-model="permissions.print" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
        </el-form-item>
        <el-form-item label="可下载: ">
          <el-switch v-model="permissions.download" :active-value="1" :inactive-value="0" :disabled="true"></el-switch>
        </el-form-item>
        <el-form-item label="添加水印: ">
          <span>默认水印</span>
        </el-form-item>
        <el-form-item label="可读页数: ">
          <span>{{permissions.readPageType==0 ? '全部' : permissions.readPage.split(',').length + "页"  }}</span>
        </el-form-item>
      </el-form>
    </div>
</template>
<script>
export default {
    props: {
    permissions: {
      type: Object,
      default: {}
    },
    shareInfo: {
      type: Object,
      default: {}
    },
  },
  mounted(){
  }
}
</script>
<style lang="less" scoped>
@import "../styles/index";
  .detail-read {
    width: 100%;
    margin-top: 32px;
    .title-box {
      border-bottom: 1px dashed #e7eef4;
      padding-bottom: 10px;
    }
    .read-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        display: inline-block;
        height: 20px;
        width: 5px;
        background: @Brand;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .add {
      font-size: @h3;
      span {
        border-radius: 50px;
        background: #59b880;
        padding: 2px;
        i {
          color: #fff;
        }
      }
    }
    .read-bottom-box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .read-context {
      width: 100%;
      table {
        padding-bottom: 10px;
      }
      .el-table__body {
        tbody {
          display: flex;
          flex-wrap:wrap;
          .el-table__row {
            width:50%;
            td {
              border:0;
            }
          }
        }
      }
      .title-link {
        white-space: nowrap;
        position: relative;
        cursor: pointer;
        font-size: @h3;
      }
    }
    .el-form {
      margin: 10px 0;
    }
    .el-form-item {
      margin-bottom: 10px;
       /deep/ .el-form-item__content {
        margin-left: 90px !important;
        padding-left: 3px;
      }
    }
   }
  .detail-power /deep/ .el-form-item__content {
    border-bottom: 1px dashed #dadada;
  }
</style>


